import auth from "@component-library/auth";
import { captureException } from "@component-library/sentry";
import { useToastStore } from "@component-library/store/toasts";
import axios from "axios";

export function useAxiosInterceptors(store: any) {
    // Add project ID to requests
    axios.interceptors.request.use(
        function (config) {
            if (!config.params) {
                config.params = {};
            }

            const isExternalUrl =
                !!config.url?.match(/^http(s)?/) &&
                !config.url?.includes(import.meta.env.VITE_APP_URL);
            if (!config.params.project_id && !isExternalUrl) {
                config.params.project_id =
                    store.getters.get_project_field_by_key('project_id');
            }

            if (!config.params.matrix && !isExternalUrl) {
                config.params.matrix = store.state.matrix;
            }

            const reportToken = store.state.reporter?.reportToken;
            if (reportToken) {
                config.params.report_token = reportToken;
                const reportId = store.state.reporter.report?.id;
                if (reportId) {
                    config.params.report_id = reportId;
                }
            }

            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    //Intercept errors if project, or user session expired.
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (!error.response) {
                captureException(error);
                return Promise.reject(error);
            }

            const status = error.response.status;
            if (status !== 422) {
                captureException(error);
            }

            if (
                status == 401 &&
                !error.request.responseURL.includes(import.meta.env.VITE_APP_URL)
            ) {
                console.error('Ignoring 401 error status', error.request.responseURL);
                return true;
            }

            if (status == 401) {
                console.error('Clearing login as we received an 401 error status from the app');
                if (auth.check()) {
                    (async () => {
                        if (auth.impersonating()) {
                            try { await auth.unimpersonate(); } catch (err) {
                                console.error(err);
                            }
                        }
                        await auth.logout();
                        window.location.reload();
                    })();
                }
            }

            const errorMessage = error.response.data?.message;

            if (
                status == 403 &&
                (errorMessage == 'Project number has not been set.' ||
                    errorMessage == 'You do not have access to this project.')
            ) {
                useToastStore().error(errorMessage);

                localStorage.removeItem('project_id');
            }

            if (status == 413) {
                useToastStore().error(
                    'The uploaded file size exceeded our upload limit.'
                );
            }

            if (status === 429 || status === 420) {
                useToastStore().error(
                    'Too many requests. Please try again in a few minutes.'
                );
            }

            return Promise.reject(error);
        }
    );

}