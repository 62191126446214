import {
  getUnitByKey,
  safeMultiply,
} from '@component-library/utils/unit-conversion';

export enum ChemicalFieldKey {
  UNIT_FIELD,
  EQL_FIELD,
  RPD_FIELD,
  CASNO_FIELD,
  METHOD_FIELD,
  T_OR_F_FIELD,
}

export type Field = {
  key: ChemicalFieldKey;
  title: string;
  menu_key?: string;
  chemical_key?: string;
  is_default?: boolean;
  is_selectable?: boolean;
  parse?: (val: any) => any;
};

export const CHEMICAL_FIELDS: Field[] = [
  {
    key: ChemicalFieldKey.UNIT_FIELD,
    title: 'Units',
    menu_key: 'unit_column',
    chemical_key: 'units',
    is_default: true,
    is_selectable: true,
    parse: (val) => {
      if (!val) {
        return;
      }

      return getUnitByKey(val);
    },
  },
  {
    key: ChemicalFieldKey.EQL_FIELD,
    title: 'EQL',
    menu_key: 'eql_result_column',
    chemical_key: 'eql_result',
    is_default: true,
    is_selectable: true,
    parse: (val) => {
      if (val === null) {
        return;
      }

      return safeMultiply(val, 1);
    },
  },
  {
    key: ChemicalFieldKey.RPD_FIELD,
    title: 'RPD Acceptance',
    menu_key: 'rpd_column',
    chemical_key: 'rpd_acceptance',
    is_default: true,
    is_selectable: false,
    parse: (val) => {
      if (!val) {
        return;
      }
      return `${val}%`;
    },
  },
  {
    key: ChemicalFieldKey.CASNO_FIELD,
    title: 'CAS No.',
    menu_key: 'eql_result_column',
    chemical_key: 'casno',
    is_default: false,
    is_selectable: true,
  },
  {
    key: ChemicalFieldKey.T_OR_F_FIELD,
    title: 'Total or Filtered',
    menu_key: 'eql_result_column',
    chemical_key: 'total_or_filtered',
    is_default: false,
    is_selectable: true,
  },
];

export const getDefaultChemicalFields = () => {
  return CHEMICAL_FIELDS.filter((field) => field.is_default);
};

export const getPathwaysFromChemical = (chemical) => {
  if (!chemical.item_guidelines) {
    return [];
  }

  return chemical.item_guidelines
    .map((g) => g.pathways)
    .filter((pathway, index, pathways) => pathways.indexOf(pathway) === index);
};

export const hasChemicalPathways = (chemical) => {
  return (
    chemical.item_guidelines &&
    chemical.item_guidelines.filter((g) => g.pathways).map((g) => g.pathways)
      .length > 1
  );
};

export const getChemicalsByGroup = (chemicals, group) => {
  if (group.is_other) {
    return chemicals.filter((c) => !c.chemical_group_id);
  }

  return chemicals.filter(
    (c) =>
      c.chemical_group_id == group.id &&
      (group.units !== null ? c.units == group.units : true)
  );
};

export const parseFieldValue = (chemical, field) => {
  const value = chemical[field.chemical_key];
  return field.parse ? field.parse(value) : value;
};

export const markLastInGroup = (chemicals) => {
  if (!chemicals) {
    console.debug('No chemicals to mark as last in group');
    return [];
  }

  const seenGroupIds = new Set();

  for (let i = chemicals.length - 1; i >= 0; i--) {
    const obj = chemicals[i];
    const groupId = obj?.chemical_group_id;

    if (groupId) {
      if (seenGroupIds.has(groupId)) {
        continue;
      }

      obj.is_last_in_group = true;
      seenGroupIds.add(groupId);
    }
  }

  return chemicals;
};
