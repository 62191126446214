import { getMeasurementShortTitle } from '@/js/helpers/measurement.js';
import { getResultValue } from '@/js/helpers/result.js';
import { formatSampleDepth as helperFormatSampleDepth } from '@/js/helpers/sample.js';
import { getStylingPropertiesByMethodAsString } from '@/js/helpers/scenario.js';
import store from '@/js/store';
import { BLACK, WHITE } from '../business-logic/color';
import {
  getChemicalFieldValue,
  getSampleFieldValue,
} from '../business-logic/evalu8';
import { getSampleTitle } from '../lib/olbm/layer/sample/utils';
import { NAMESPACE } from '../store';

/**
 * The Sample Chemicals Call-out Handler.
 */
class PopupHandler {
  constructor(project, selectedFigure, sample, allChemicalGroups) {
    this.project = project;
    this.selectedFigure = selectedFigure;
    this.sample = sample;
    this.allChemicalGroups = allChemicalGroups;
    this.resultExceedances = NAMESPACE.getGetter(
      store,
      'getResultExceedanceBySample'
    )(sample, 'result');
    this.scenarioSet = NAMESPACE.getState(store, 'scenarioSet');
  }

  getHiddenFormattedDepths() {
    return (
      this.selectedFigure.enviro_callout_filter?.hiddenFormattedDepths ?? []
    );
  }

  getPopupTable(
    style = {
      textColor: BLACK,
      textShadow: 'none',
      fontWeight: 'normal',
      backgroundColor: WHITE,
    },
    extraInfo = { isScientificFormat: false }
  ) {
    const { content: headContent, columnCounts: headColumnCounts } =
      this._getHead(style);
    const bodyContent = this._getBodyContent(
      extraInfo,
      headColumnCounts,
      style
    );

    return bodyContent !== ''
      ? `<table class="table table-bordered builtin-callout-table">
          <thead>
            ${headContent}
          </thead>
          <tbody>
            ${bodyContent}
          </tbody>
         </table>`
      : undefined;
  }

  /**
   *
   * @param {*} style
   * @param {Record<string, string>} props Used to map CSS property names, e.g. from textColor to color
   * @returns
   */
  _extractStyle(style, props) {
    const targetProps = Object.keys(props);
    return Object.keys(style).reduce((accu, prop) => {
      if (!targetProps.includes(prop)) {
        return accu;
      }

      const actualProp = props[prop];
      accu[actualProp] = style[prop];
      return accu;
    }, {});
  }

  _formatStyle(style) {
    return Object.keys(style)
      .reduce((accu, prop) => {
        accu.push(`${prop}: ${style[prop]}`);
        return accu;
      }, [])
      .join(';');
  }

  _getHead(style) {
    const formattedStyle = this._formatStyle(
      this._extractStyle(style, {
        textColor: 'color',
        backgroundColor: 'background-color',
      })
    );
    const sampleTitle = getSampleTitle(this.sample);
    const chemicalFieldColumns = this._getChemicalFieldColumns(formattedStyle);
    const sampleDepthColumns = this._getSampleDepthColumns(formattedStyle);

    return {
      content: `<tr>
                  <th style="font-weight: 600; ${formattedStyle}">${sampleTitle}</th>
                  ${chemicalFieldColumns.join('')}
                  ${sampleDepthColumns.join('')}
                </tr>`,
      columnCounts: {
        chemicalFieldColumnCount: chemicalFieldColumns.length,
        sampleDepthColumnCount: sampleDepthColumns.length,
      },
    };
  }

  _getChemicalFieldColumns(formattedStyle) {
    const {
      enviro_callout_filter: { selectedChemicalFields = [] },
    } = this.selectedFigure;
    return selectedChemicalFields.map(
      (scf) => `<th style="${formattedStyle}">${scf.title}</th>`
    );
  }

  _checkIsSampleDepthColumnVisible(sample, isDup = false) {
    const {
      enviro_callout_filter: { isDupVisible },
      hide_depths_without_exceedances,
    } = this.selectedFigure;
    const hiddenFormattedDepths = this.getHiddenFormattedDepths();
    return (
      !!sample &&
      this.checkHasResults(sample) &&
      !hiddenFormattedDepths.includes(this.formatSampleDepth(sample)) &&
      (!isDup || isDupVisible) &&
      (!hide_depths_without_exceedances || this.checkHasExceedances(sample))
    );
  }

  _getSampleDepthColumns(formattedStyle) {
    const result = [];

    if (this._checkIsSampleDepthColumnVisible(this.sample)) {
      result.push({
        startDepth: this.sample.start_depth ?? 0,
        formattedDepth: this.formatSampleDepth(this.sample),
        class: '',
      });
    }

    if (this._checkIsSampleDepthColumnVisible(this.sample.duplicate, true)) {
      result.push({
        startDepth: this.sample.duplicate.start_depth ?? 0,
        formattedDepth: 'Dup',
        class: 'dupe',
      });
    }

    // The child_samples is null when the sample is a brand new one(created by the tool).
    this.sample.child_samples?.forEach((cs) => {
      if (this._checkIsSampleDepthColumnVisible(cs)) {
        result.push({
          startDepth: cs.start_depth ?? 0,
          formattedDepth: this.formatSampleDepth(cs),
          class: '',
        });
      }

      if (this._checkIsSampleDepthColumnVisible(cs.duplicate, true)) {
        result.push({
          startDepth: cs.duplicate.start_depth ?? 0,
          formattedDepth: 'Dup',
          class: 'dupe',
        });
      }
    });

    return result
      .sort((sd1, sd2) => sd1.startDepth - sd2.startDepth)
      .map(
        (sd) =>
          `<th class="${sd.class}" style="${formattedStyle}">${sd.formattedDepth}</th>`
      );
  }

  _getBodyContent(extraInfo, headColumnCounts, style) {
    const {
      enviro_callout_filter: {
        isChemicalGroupsUsed = false,
        selectedChemicalGroups = [],
        selectedChemicalsByGroup,
        selectedSampleFields = [],
      },
      results,
    } = this.selectedFigure;
    const { chemicalFieldColumnCount, sampleDepthColumnCount } =
      headColumnCounts;
    const rows = [];

    for (const sampleField of selectedSampleFields) {
      const row = this._makeSampleFieldRow(
        sampleField,
        headColumnCounts,
        style
      );
      rows.push(row);
    }

    if (isChemicalGroupsUsed) {
      const formattedStyle = this._formatStyle(
        this._extractStyle(style, {
          textColor: 'color',
          backgroundColor: 'background-color',
        })
      );
      const _selectedChemicalGroups = selectedChemicalGroups.reduce(
        (accu, scg) => {
          const _scg = this.allChemicalGroups.find((cg) => cg.id === scg);
          if (_scg) {
            accu.push(_scg);
          }
          return accu;
        },
        []
      );
      for (const scg of _selectedChemicalGroups) {
        const resultsOfGroup = results.filter((r) =>
          (selectedChemicalsByGroup[scg.id] ?? []).includes(r.chemical_id)
        );
        if (resultsOfGroup.length === 0) {
          continue;
        }
        rows.push(`<tr>
                    <td colspan="${
                      chemicalFieldColumnCount + sampleDepthColumnCount + 1
                    }" style="${formattedStyle}">${scg.group_title}</td>
                  </tr>`);
        for (const rog of resultsOfGroup) {
          const row = this._makeResultRow(rog, extraInfo, style);
          if (row) {
            rows.push(row);
          }
        }
      }
    } else {
      for (const r of results) {
        const row = this._makeResultRow(r, extraInfo, style);
        if (row) {
          rows.push(row);
        }
      }
    }

    return rows.join('');
  }

  _makeSampleFieldRow(sampleField, headColumnCounts, style) {
    const formattedStyle = this._formatStyle(
      this._extractStyle(style, {
        textColor: 'color',
        backgroundColor: 'background-color',
      })
    );

    const { chemicalFieldColumnCount } = headColumnCounts;
    const paddingCell =
      chemicalFieldColumnCount > 0
        ? `<td colspan="${chemicalFieldColumnCount}" style="${formattedStyle}"></td>`
        : '';

    const values = [];
    if (this._checkIsSampleDepthColumnVisible(this.sample)) {
      values.push(getSampleFieldValue(this.sample, sampleField));
    }
    if (this._checkIsSampleDepthColumnVisible(this.sample.duplicate, true)) {
      values.push(getSampleFieldValue(this.sample.duplicate, sampleField));
    }
    this.sample.child_samples?.forEach((cs) => {
      if (this._checkIsSampleDepthColumnVisible(cs)) {
        values.push(getSampleFieldValue(cs, sampleField));
      }

      if (this._checkIsSampleDepthColumnVisible(cs.duplicate, true)) {
        values.push(getSampleFieldValue(cs.duplicate, sampleField));
      }
    });
    const valueCells = values
      .map((v) => `<td style="font-weight: 500; ${formattedStyle}">${v}</td>`)
      .join('');

    return `<tr>
              <td style="font-weight: 600; ${formattedStyle}">${sampleField.title}</td>
              ${paddingCell}
              ${valueCells}
            </tr>`;
  }

  /**
   *
   * @param {SamplChemical} result
   * @param {*} extraInfo
   * @param {*} style
   * @returns
   */
  _makeResultRow(result, extraInfo, style) {
    const formattedStyle = this._formatStyle(
      this._extractStyle(style, {
        textColor: 'color',
        backgroundColor: 'background-color',
      })
    );
    const {
      enviro_callout_filter: { selectedChemicalFields = [] },
    } = this.selectedFigure;
    const resultColumns = this._getResultColumns(result, extraInfo);
    return resultColumns !== ''
      ? `<tr>
          <td style="${formattedStyle}">${result.display_title}</td>
          ${selectedChemicalFields
            .map(
              (scf) =>
                '<td style="' +
                formattedStyle +
                '">' +
                getChemicalFieldValue(result, scf) +
                '</td>'
            )
            .join('')}
          ${resultColumns}
         </tr>`
      : undefined;
  }

  _checkIsResultColumnVisible(sample, units, isDup = false) {
    const {
      enviro_callout_filter: { visibleUnits },
    } = this.selectedFigure;
    return (
      this._checkIsSampleDepthColumnVisible(sample, isDup) &&
      (!visibleUnits || visibleUnits.includes(units))
    );
  }

  /**
   *
   * @param {SampleChemical} chemicalResult
   * @param {*} extraInfo
   * @returns
   */
  _getResultColumns(chemicalResult, extraInfo) {
    const {
      hide_chemicals_without_exceedances: hideChemicalsWithoutExceedances,
      hide_non_detect_chemicals: hideNonDetectChemicals,
    } = this.selectedFigure;
    const { isScientificFormat } = extraInfo;
    const { units } = chemicalResult;
    const results = chemicalResult.sample_items;
    const resultColumns = [];

    if (this._checkIsResultColumnVisible(this.sample, units)) {
      const parentResult = results.find((r) => r.sample_id == this.sample.id);
      resultColumns.push({
        startDepth: this.sample.start_depth ?? 0,
        style: this._getResultExceedanceStyling(parentResult),
        class: 'text-center',
        displayResult: this.getDisplayResult(
          units,
          getResultValue(parentResult, this.scenarioSet, isScientificFormat)
        ),
        isNonDetect: !!parentResult?.prefix,
      });
    }

    if (this._checkIsResultColumnVisible(this.sample.duplicate, units, true)) {
      const duplicateResult = results.find(
        (r) => r.sample_id == this.sample.duplicate.id
      );
      resultColumns.push({
        startDepth: this.sample.duplicate.start_depth ?? 0,
        style: this._getResultExceedanceStyling(duplicateResult),
        class: 'text-center',
        displayResult: this.getDisplayResult(
          units,
          getResultValue(duplicateResult, this.scenarioSet, isScientificFormat)
        ),
        isNonDetect: !!duplicateResult?.prefix,
      });
    }

    // The child_samples is null when the sample is a brand new one(created by the tool).
    this.sample.child_samples?.forEach((cs) => {
      if (this._checkIsResultColumnVisible(cs, units)) {
        const childResult = results.find((r) => r.sample_id == cs.id);
        resultColumns.push({
          startDepth: cs.start_depth ?? 0,
          style: this._getResultExceedanceStyling(childResult),
          class: 'text-center',
          displayResult: this.getDisplayResult(
            units,
            getResultValue(childResult, this.scenarioSet, isScientificFormat)
          ),
          isNonDetect: !!childResult?.prefix,
        });
      }

      if (this._checkIsResultColumnVisible(cs.duplicate, units, true)) {
        const duplicateResult = results.find(
          (r) => r.sample_id == cs.duplicate.id
        );
        resultColumns.push({
          startDepth: cs.duplicate.start_depth ?? 0,
          style: this._getResultExceedanceStyling(duplicateResult),
          class: 'text-center',
          displayResult: this.getDisplayResult(
            units,
            getResultValue(
              duplicateResult,
              this.scenarioSet,
              isScientificFormat
            )
          ),
          isNonDetect: !!duplicateResult?.prefix,
        });
      }
    });

    if (
      hideChemicalsWithoutExceedances &&
      !resultColumns.find((rc) => rc.style !== '')
    ) {
      return '';
    }

    if (
      hideNonDetectChemicals &&
      resultColumns.filter((c) => !c.isNonDetect).length === 0
    ) {
      return '';
    }

    return resultColumns
      .sort((a, b) => a.startDepth - b.startDepth)
      .map(
        (item) =>
          `<td class="${item.class}" style="${item.style}">${item.displayResult}</td>`
      )
      .join('');
  }

  _getExceedanceProperties(result, exceedances) {
    if (exceedances.length == 0) {
      return [];
    }

    const stylingProperties = [];

    exceedances.forEach((exceedance) => {
      const styleByScenario = NAMESPACE.getGetter(
        store,
        'getStyleByScenario'
      )(exceedance);

      let highestValue = exceedance.exceedances.find(
        (i) => i.item_id == result.id
      ).value;

      let cellStyling = styleByScenario?.exceedance_cell_styling;
      if (exceedance.criteria_type === 'landuse' && result.prefix) {
        cellStyling = this.scenarioSet?.nd_exceedance_cell_styling || {
          background_color: '#f8f1d7',
        };

        highestValue = Infinity;
      }

      const properties = getStylingPropertiesByMethodAsString(cellStyling);

      stylingProperties.push({
        highestValue,
        properties,
      });
    });

    return stylingProperties;
  }

  /**
   *
   * @param {SampleItem} result
   * @returns A CSS style string
   */
  _getResultExceedanceStyling(result) {
    if (!result) {
      return '';
    }

    const exceedances = this.resultExceedances;

    let stylingProperties = [];

    // get landuse exceedances
    const landuseExceedances = exceedances.filter(
      (e) =>
        e.criteria_type == 'landuse' &&
        e.exceedances.findIndex((i) => i.item_id == result.id) != -1
    );

    stylingProperties = [
      ...stylingProperties,
      ...this._getExceedanceProperties(result, landuseExceedances),
    ];

    // get criteria exceedances
    const criteriaExceedances = exceedances.filter(
      (e) =>
        e.criteria_type == 'criteria' &&
        e.exceedances.findIndex((i) => i.item_id == result.id) != -1
    );

    stylingProperties = [
      ...stylingProperties,
      ...this._getExceedanceProperties(result, criteriaExceedances),
    ];

    let styling = '';

    stylingProperties
      .sort((a, b) => a.highestValue - b.highestValue)
      .forEach((style) => {
        styling += style.properties;
      });

    return styling;
  }

  checkHasResults(sample) {
    const { sampled_date: sampledDate, sample_type: sampleType } = sample;
    const {
      enviro_callout_filter: { visibleTypes, visibleDates },
    } = this.selectedFigure;

    if (
      (visibleDates && !visibleDates.includes(sampledDate)) ||
      (visibleTypes && !visibleTypes.includes(sampleType))
    ) {
      return false;
    }

    return NAMESPACE.getGetter(store, 'checkHasResults')(
      this.selectedFigure,
      sample
    );
  }

  checkHasExceedances(sample) {
    return NAMESPACE.getGetter(store, 'checkHasExceedances')(
      this.selectedFigure,
      sample
    );
  }

  formatSampleDepth(sample) {
    return (
      helperFormatSampleDepth(
        sample,
        getMeasurementShortTitle(this.project.measurement_type)
      ) || ''
    );
  }

  // specifically for asbestos, user needs to manually update result based on the comment
  getDisplayResult(units, display_result) {
    if (units == 'comment' && (display_result == 0 || display_result == 1)) {
      display_result = 'COMMENT';
    }

    if (units == 'detect') {
      if (display_result == 0) {
        display_result = 'ABSENT';
      }
      if (display_result == 1) {
        display_result = 'PRESENT';
      }
    }

    return display_result;
  }
}

export default PopupHandler;
