import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import NProgress from 'nprogress';
import { FEATURES, awaitAccess } from '@component-library/feature-manager';
import store from './store';
import { useToastStore } from '@component-library/store/toasts';
import { useProjectStore } from '@component-library/store/project';
import { Tenant } from '@component-library/store/tenant';
import { Project } from '@component-library/project';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';
import { captureMessage } from '@component-library/sentry';
import { UserWithSession } from '@component-library/user';
import auth from '@component-library/auth';

/** Project Modules */
const ProjectLayout = () => import('@/js/layouts/Project.vue');
const DataInsightsLayout = () => import('@/js/layouts/DataInsights.vue');

const DashboardModule = () =>
  import('@/js/modules/project/dashboard/index.vue');
const DataTableIFrameModule = () =>
  import('@/js/modules/project/data-table/IframeTableViewer.vue');
const BulkEditorModule = () =>
  import('@/js/modules/project/bulk-editor/index.vue');
const GraphModule = () => import('@/js/modules/project/graph/index.vue');
const PhotologModule = () => import('@/js/modules/project/photo-log/index.vue');
const DataInsightsGraphFullScreen = () =>
  import('@/js/modules/project/data-insights/GraphFullscreen.vue');
const DataInsightsModule = () =>
  import('@/js/modules/project/data-insights/index.vue');
const DeliverOverviewPage = () =>
  import('@/js/modules/documents/DeliverOverviewPage.vue');
const DocumentModule = () => import('@/js/modules/documents/index.vue');
const DocumentEditorModule = () =>
  import('@/js/modules/documents/DocumentEditor.vue');
const DocumentExcelModule = () =>
  import('@/js/modules/documents/ExcelDocumentsPage.vue');
const ExcelEditorModule = () =>
  import('@/js/modules/documents/ExcelEditor.vue');

/**
 * Figure Modules
 */
const FigureLayout = () => import('@/js/layouts/Figure.vue');

const MapViewModule = () => import('@maps/views/MapEditor.vue');
const MapExportModule = () => import('@/js/pages/maps/ExportView.vue');
const MapIFrameModule = () => import('@maps/views/IframeMapViewer.vue');

/** Verification Modules */
const VerificationLayout = () => import('@/js/layouts/Verification.vue');

const VerificationOverviewModule = () =>
  import('@/js/modules/project/verification/index.vue');
const VerificationPcbModule = () =>
  import('@/js/modules/project/verification/pcb/index.vue');
const VerificationDdtModule = () =>
  import('@/js/modules/project/verification/ddt/index.vue');
const VerificationAsbestosModule = () =>
  import('@/js/modules/project/verification/asbestos/index.vue');
const VerificationBenzoModule = () =>
  import('@/js/modules/project/verification/benzo/index.vue');
const VerificationDrinModule = () =>
  import('@/js/modules/project/verification/drin/index.vue');

/** Admin Modules */
const AdminLayout = () => import('@/js/layouts/Admin.vue');

const AdminDashboardModule = () =>
  import('@/js/modules/admin/dashboard/index.vue');
const AdminChemicalModule = () =>
  import('@/js/modules/admin/chemicals/index.vue');
const AdminStandardManagement = () =>
  import('@/js/modules/admin/standards/index.vue');
const ProjectBrowserModule = () =>
  import('@/js/modules/admin/project-browser/index.vue');
const FeatureHighlightModule = () =>
  import('@/js/modules/admin/feature-highlight/index.vue');
const AIReviewGuidelineManagement = () =>
  import('@/js/modules/admin/ai-review/index.vue');
/** Standard Modules */
const StandardLayout = () => import('@/js/layouts/Standard.vue');

const ReportLayout = () => import('@/js/layouts/Report.vue');

const PathwayModule = () =>
  import('@/js/modules/project/list/pages/ProjectListPage.vue');

const ProjectSettingsModule = () =>
  import('@/js/modules/project/management/ProjectSettings.vue');
const AccountNavigationModule = () =>
  import('@/js/modules/account/management/navigation/index.vue');
const AccountProfileModule = () =>
  import('@/js/modules/account/management/profile/AccountSettings.vue');
const AccountCompanyOverviewModule = () =>
  import('@/js/modules/account/management/company-overview/index.vue');
const FigurePrintEditorLayout = () =>
  import('@/js/layouts/FigurePrintEditor.vue');
const FigurePrintLayoutEditorModule = () =>
  import(
    '@/js/modules/account/management/figure-print-layout-editor/index.vue'
  );
const LeaderboardModule = () => import('@/js/modules/leaderboard/index.vue');

/**
 * Standards Management
 */
const StandardManagementLayout = () =>
  import('@/js/layouts/StandardManagement.vue');
const CompanyStandardsListModule = () =>
  import('@/js/modules/company/standards/list/index.vue');
const CompanyStandardsManagementModule = () =>
  import('@/js/modules/company/standards/management/index.vue');

/** Support Modules */
const TutorialLayout = () => import('@/js/layouts/Tutorial.vue');

const SupportDashboardModule = () =>
  import('@/js/modules/support/help/index.vue');
const SupportTutorialModule = () =>
  import('@/js/modules/support/tutorial/index.vue');
const SupportTagModule = () => import('@/js/modules/support/tag/index.vue');

/** Account Modules */
const AuthLayout = () => import('@/js/layouts/Auth.vue');

const AccountRegisterModule = () =>
  import('@/js/modules/account/register/index.vue');

/** Misc Modules */
const MiscLayout = () => import('@/js/layouts/Misc.vue');

const AccountResetModule = () => import('@/js/modules/account/reset/index.vue');
const RequestModule = () => import('@/js/modules/project/request/index.vue');
const RedirectToProject = () =>
  import('@/js/modules/project/redirect/RedirectToProject.vue');

/**
 * Reporter Modules
 */
const ReporterListModule = () =>
  import('@visual-reporter/modules/list/index.vue');
const ReporterPreviewModule = () =>
  import('@visual-reporter/modules/preview/index.vue');
const ReporterEditorModule = () =>
  import('@visual-reporter/modules/editor/index.vue');

/** Other Modules */
const NotFound = () => import('@/js/pages/Error404Page.vue');
const Handover = () => import('@/js/pages/Handover.vue');
const CompanySafety = () => import('@/js/modules/company/safety/index.vue');

Vue.use(VueRouter);

export const routes = [
  {
    path: '',
    redirect: '/projects',
    meta: {
      auth: null,
      projectRequired: false,
    },
  },
  {
    path: '/project',
    component: ProjectLayout,
    meta: {
      auth: true,
      projectRequired: true,
    },
    children: [
      {
        path: '/p/:projectUuid/data-manager/import',
        name: 'project_dashboard_import',
        component: DashboardModule,
        meta: {
          figureOnlySupport: true,
          title: 'Data Manager',
          hideFooter: true,
          hideBreadcrumb: true,
        },
      },
      {
        path: '/p/:projectUuid/data-manager/tab-logs/:itemId?/:tab?',
        name: 'tab_logs_manager',
        component: () => import('@/js/modules/tab-logs/TabLogsManagerPage.vue'),
        meta: {
          figureOnlySupport: true,
          title: 'Data Manager - Tab Logs',
          hideFooter: true,
        },
      },
      {
        path: '/p/:projectUuid/data-manager/:module?/:id?',
        name: 'project_dashboard',
        component: DashboardModule,
        meta: {
          figureOnlySupport: true,
          title: 'Data Manager',
          hideFooter: true,
          hideBreadcrumb: true,
        },
      },
      {
        path: '/p/:projectUuid/data/bulk-editor',
        name: 'bulk_editor',
        component: BulkEditorModule,
        meta: {
          title: 'Bulk Editor',
          figureOnlySupport: true,
          maxHeightContainer: true,
          hideFooter: true,
          hideBreadcrumb: true,
        },
      },
      {
        path: '/p/:projectUuid/graphs',
        name: 'project_graph',
        component: GraphModule,
        meta: {
          title: 'Graphs',
          hideBreadcrumb: true,
          roleRequired: AVAILABLE_PERMISSIONS.EVALU8,
        },
      },
      {
        path: '/p/:projectUuid/photolog',
        name: 'project_photolog',
        component: PhotologModule,
        meta: {
          figureOnlySupport: true,
          title: 'Photo Log',
          roleRequired: AVAILABLE_PERMISSIONS.EVALU8,
        },
      },
      {
        path: '/p/:projectUuid/deliver',
        name: 'deliver_overview',
        component: DeliverOverviewPage,
        meta: {
          title: 'Deliver Overview',
          figureOnlySupport: true,
          clientOnlyView: false,
        },
      },
      {
        path: '/p/:projectUuid/deliver/word',
        component: DocumentModule,
        meta: {
          figureOnlySupport: true,
          title: 'Auto Docs (Word)',
          hideBreadcrumb: true,
          featureRequired: FEATURES.AUTO_DOCS_WORD,
          roleRequired: AVAILABLE_PERMISSIONS.AUTO_DOCS_WORD,
        },
      },
      {
        path: '/p/:projectUuid/data-events',
        name: 'data_events',
        component: () =>
          import('./modules/data-events/pages/DataEventIndexPage.vue'),
        meta: {
          title: 'Data Events',
          figureOnlySupport: true,
          clientOnlyView: false,
          roleRequired: AVAILABLE_PERMISSIONS.DATA_EVENTS,
          hideBreadcrumb: true,
        },
      },
      {
        path: '/p/:projectUuid/data-events/create',
        name: 'data_events_create',
        component: () =>
          import('./modules/data-events/pages/DataEventFormPage.vue'),
        meta: {
          title: 'Data Events',
          figureOnlySupport: true,
          clientOnlyView: false,
          hideBreadcrumb: true,
          roleRequired: AVAILABLE_PERMISSIONS.DATA_EVENTS,
        },
      },
      {
        path: '/p/:projectUuid/data-events/:eventId',
        name: 'data_events_edit',
        component: () =>
          import('./modules/data-events/pages/DataEventFormPage.vue'),
        meta: {
          title: 'Data Events',
          figureOnlySupport: true,
          clientOnlyView: false,
          hideBreadcrumb: true,
          roleRequired: AVAILABLE_PERMISSIONS.DATA_EVENTS,
        },
      },
      {
        path: '/p/:projectUuid/files',
        name: 'files',
        component: () => import('./modules/documents/FileBrowserPage.vue'),
        meta: {
          figureOnlySupport: true,
          title: 'File Browser',
          roleRequired: AVAILABLE_PERMISSIONS.FILE_BROWSER,
        },
      },
      {
        path: '/p/:projectUuid/file/:fileId',
        component: () => import('./modules/documents/FileViewerPage.vue'),
        meta: {
          figureOnlySupport: true,
          title: 'File Viewer',
          hideBreadcrumb: true,
          hideFooter: true,
          layoutPadding: false,
          maxHeightContainer: true,
          featureRequired: FEATURES.FILE_BROWSER,
          roleRequired: AVAILABLE_PERMISSIONS.FILE_BROWSER,
        },
      },
      {
        path: '/p/:projectUuid/deliver/word/editor/:docId',
        component: DocumentEditorModule,
        meta: {
          figureOnlySupport: true,
          hideBreadcrumb: true,
          hideFooter: true,
          layoutPadding: false,
          maxHeightContainer: true,
          title: 'Document Editor',
          featureRequired: FEATURES.AUTO_DOCS_WORD,
          roleRequired: AVAILABLE_PERMISSIONS.AUTO_DOCS_WORD,
        },
      },
      {
        path: '/p/:projectUuid/deliver/excel',
        component: DocumentExcelModule,
        meta: {
          figureOnlySupport: true,
          title: 'Auto Docs (Excel)',
          hideBreadcrumb: true,
          featureRequired: FEATURES.AUTO_DOCS_EXCEL,
          roleRequired: AVAILABLE_PERMISSIONS.AUTO_DOCS_EXCEL,
        },
      },
      {
        path: '/p/:projectUuid/deliver/excel/editor/:docId',
        component: ExcelEditorModule,
        meta: {
          figureOnlySupport: true,
          hideBreadcrumb: true,
          hideFooter: true,
          title: 'Excel Builder',
          featureRequired: FEATURES.AUTO_DOCS_EXCEL,
          roleRequired: AVAILABLE_PERMISSIONS.AUTO_DOCS_EXCEL,
        },
      },
      {
        path: '/p/:projectUuid/deliver/csv-builder',
        name: 'csv_builder',
        component: () =>
          import('./modules/project/csv-builder/CSVBuilderPage.vue'),
        meta: {
          figureOnlySupport: true,
          title: 'CSV Builder',
          roleRequired: AVAILABLE_PERMISSIONS.EVALU8,
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/csm',
    component: ProjectLayout,
    meta: {
      auth: true,
      projectRequired: true,
      featureRequired: FEATURES.VECTOR_EDITOR,
    },
    children: [
      {
        path: 'list',
        name: 'vector_list',
        component: () => import('./modules/vector/list/index.vue'),
        meta: {
          title: 'Conceptual Site Models',
          figureOnlySupport: true,
          roleRequired: AVAILABLE_PERMISSIONS.VECTOR_EDITOR,
        },
      },
      {
        path: 'create',
        name: 'vector_create',
        component: () => import('./modules/vector/create/index.vue'),
        meta: {
          title: 'Create CSM',
          figureOnlySupport: true,
          roleRequired: AVAILABLE_PERMISSIONS.VECTOR_EDITOR,
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/csm/editor/:id',
    component: FigureLayout,
    meta: {
      auth: true,
      projectRequired: true,
      searchable: false,
      featureRequired: FEATURES.VECTOR_EDITOR,
    },
    children: [
      {
        path: '',
        name: 'vector_editor',
        component: () => import('./modules/vector/editor/index.vue'),
        meta: {
          title: 'Vector Editor',
          searchable: false,
          figureOnlySupport: true,
          roleRequired: AVAILABLE_PERMISSIONS.VECTOR_EDITOR,
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/deliver/doc-review/upload',
    component: ProjectLayout,
    meta: {
      auth: true,
      projectRequired: true,
    },
    children: [
      {
        path: '',
        name: 'doc-review-upload',
        component: () => import('./modules/ai-review/create/index.vue'),
        meta: {
          figureOnlySupport: true,
          title: 'AI Review',
          page: 'upload',
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/deliver/doc-review/:id?',
    component: FigureLayout,
    meta: {
      auth: true,
      searchable: false,
      projectRequired: true,
    },
    children: [
      {
        path: '',
        name: 'doc-review-report',
        component: () => import('./modules/ai-review/viewer/index.vue'),
        meta: {
          figureOnlySupport: true,
          title: 'AI Review',
          page: 'review',
        },
      },
    ],
  },
  {
    path: '/table-iframe/:block_id/:token?',
    name: 'table_iframe',
    component: DataTableIFrameModule,
    meta: {
      searchable: false,
      auth: null,
    },
  },
  {
    path: '/project-create',
    component: ProjectLayout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'project_create',
        component: ProjectSettingsModule,
        meta: {
          title: 'Create a project',
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/project-settings',
    component: ProjectLayout,
    meta: {
      auth: true,
      searchable: false,
      projectRequired: true,
    },
    children: [
      {
        path: '',
        name: 'project_settings',
        component: ProjectSettingsModule,
        meta: {
          title: 'Edit Project Settings',
          hideBreadcrumb: true,
          searchable: false, // In PowerModal.vue global actions
          roleRequired: AVAILABLE_PERMISSIONS.PROJECT_SETTINGS,
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/data-insights/graph/:dashboardItemId/full-screen',
    component: DataInsightsGraphFullScreen,
    meta: {
      searchable: false,
      auth: true,
    },
  },
  {
    path: '/p/:projectUuid/data-insights/:dashboardId?',
    component: DataInsightsLayout,
    meta: {
      auth: true,
      searchable: false,
      projectRequired: true,
      roleRequired: AVAILABLE_PERMISSIONS.GATHER,
    },
    children: [
      {
        path: '',
        name: 'data-insights',
        component: DataInsightsModule,
        meta: {
          title: 'Data Insights',
          figureOnlySupport: true,
          gatherRequired: true,
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/map',
    component: FigureLayout,
    meta: {
      auth: true,
      projectRequired: true,
    },
    children: [
      {
        path: '/p/:projectUuid/map/:figureId?',
        name: 'map',
        component: MapViewModule,
        meta: {
          title: 'Maps',
          keywords: ['figure'],
          figureOnlySupport: true,
          clientOnlyView: false,
          roleRequired: AVAILABLE_PERMISSIONS.MAPS,
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/gather',
    component: ProjectLayout,
    meta: {
      title: 'Gather',
      auth: true,
      projectRequired: true,
    },
    children: [
      {
        path: '',
        name: 'gather',
        component: () => import('./pages/GatherOverview.vue'),
        meta: {
          title: 'Gather',
          figureOnlySupport: true,
          clientOnlyView: false,
          roleRequired: AVAILABLE_PERMISSIONS.GATHER,
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/reports',
    component: ProjectLayout,
    meta: {
      auth: true,
      projectRequired: true,
      featureRequired: FEATURES.VISUAL_REPORTS,
    },
    children: [
      {
        path: '',
        component: ReporterListModule,
        name: 'visual_reports_list',
        meta: {
          title: 'Reports',
          figureOnlySupport: true,
          featureRequired: FEATURES.VISUAL_REPORTS,
          roleRequired: AVAILABLE_PERMISSIONS.VISUAL_REPORTS,
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/reports',
    component: ReportLayout,
    meta: {
      auth: true,
      searchable: false,
      projectRequired: true,
      featureRequired: FEATURES.VISUAL_REPORTS,
    },
    children: [
      {
        path: '/reports/:slug/edit',
        component: ReporterEditorModule,
        name: 'visual_report_editor',
        meta: {
          searchable: false,
          title: 'Visual Report Editor',
          figureOnlySupport: true,
          hideBreadcrumb: true,
          hideFooter: true,
          featureRequired: FEATURES.VISUAL_REPORTS,
          roleRequired: AVAILABLE_PERMISSIONS.VISUAL_REPORTS,
        },
      },
    ],
  },
  {
    path: '/reports/:slug/:token?',
    component: ReportLayout,
    meta: {
      searchable: false,
      auth: null,
    },
    children: [
      {
        path: '',
        component: ReporterPreviewModule,
        meta: {
          searchable: false,
          figureOnlySupport: true,
          hideBreadcrumb: true,
          hideFooter: true,
        },
      },
    ],
  },
  {
    path: '/p/:projectUuid/verification',
    component: VerificationLayout,
    meta: {
      auth: true,
      projectRequired: true,
    },
    children: [
      {
        path: '',
        name: 'verification_overview',
        component: VerificationOverviewModule,
        meta: {
          title: 'All Verifications',
          roleRequired: AVAILABLE_PERMISSIONS.EVALU8,
        },
      },
      {
        path: 'pcb',
        name: 'verification_pcb',
        component: VerificationPcbModule,
        meta: {
          title: 'PCB Verification',
          roleRequired: AVAILABLE_PERMISSIONS.EVALU8,
        },
      },
      {
        path: 'ddt',
        name: 'verification_ddt',
        component: VerificationDdtModule,
        meta: {
          title: 'DDT Verification',
          roleRequired: AVAILABLE_PERMISSIONS.EVALU8,
        },
      },
      {
        path: 'asbestos',
        name: 'verification_asbestos',
        component: VerificationAsbestosModule,
        meta: {
          title: 'Asbestos Information',
          roleRequired: AVAILABLE_PERMISSIONS.EVALU8,
        },
      },
      {
        path: 'benzo',
        name: 'verification_benzo',
        component: VerificationBenzoModule,
        meta: {
          title: 'BaP PEF / TEF Verification',
          roleRequired: AVAILABLE_PERMISSIONS.EVALU8,
        },
      },
      {
        path: 'drin',
        name: 'verification_drin',
        component: VerificationDrinModule,
        meta: {
          title: 'Aldrin + Dieldrin Verification',
          roleRequired: AVAILABLE_PERMISSIONS.EVALU8,
        },
      },
    ],
  },
  {
    path: '/standard',
    component: StandardLayout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/projects',
        name: 'project_pathway',
        component: PathwayModule,
        meta: {
          title: 'Projects',
          hideFooter: true,
          hideBreadcrumb: true,
        },
      },
      {
        path: '/workspaces',
        name: 'workspaces',
        component: () =>
          import('@/js/modules/workspaces/pages/WorkspaceListPage.vue'),
        meta: {
          title: 'Workspaces',
          featureRequired: FEATURES.WORKSPACES,
          hideFooter: true,
          hideBreadcrumb: true,
        },
      },
      {
        path: '/w/:workspaceUuid',
        name: 'workspace_dashboard',
        component: () =>
          import('@/js/modules/workspaces/pages/WorkspaceDashboardPage.vue'),
        meta: {
          title: 'Workspace',
          featureRequired: FEATURES.WORKSPACES,
          hideFooter: true,
          searchable: false,
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account',
        name: 'account',
        component: AccountNavigationModule,
        meta: {
          title: 'Account Management',
        },
      },
      {
        path: '/account/profile',
        name: 'account-profile',
        component: AccountProfileModule,
        meta: {
          title: 'Personal Info & Security',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company',
        name: 'account-company',
        component: AccountCompanyOverviewModule,
        meta: {
          title: 'Company Settings',
          keywords: [
            'integrations',
            'user management',
            'roles',
            'permissions',
            'subscription plan',
          ],
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company/general',
        name: 'account-company-general',
        component: () =>
          import(
            '@/js/modules/account/management/company/pages/GeneralCompanySettingsPage.vue'
          ),
        meta: {
          title: 'General Settings',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company/workflow',
        name: 'account-company-workflow',
        component: () =>
          import(
            '@/js/modules/account/management/company/pages/WorkflowCompanySettingsPage.vue'
          ),
        meta: {
          title: 'Company Workflow Settings',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company/maps',
        name: 'account-company-maps',
        component: () =>
          import(
            '@/js/modules/account/management/company/pages/MapCompanySettingsPage.vue'
          ),
        meta: {
          title: 'Company Map Settings',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company/enviro',
        name: 'account-company-enviro',
        component: () =>
          import(
            '@/js/modules/account/management/company/pages/EnviroCompanySettingsPage.vue'
          ),
        meta: {
          title: 'Company Evalu8 Settings',
          keywords: ['environmental settings'],
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company/visual-reporter',
        name: 'account-company-vsr',
        component: () =>
          import(
            '@/js/modules/account/management/company/pages/VisualReporterCompanySettingsPage.vue'
          ),
        meta: {
          title: 'Company VSR Settings',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company/gather',
        name: 'account-company-gather',
        component: () =>
          import(
            '@/js/modules/account/management/company/pages/GatherCompanySettingsPage.vue'
          ),
        meta: {
          title: 'Company Gather Settings',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company/wingmate',
        name: 'account-company-wingmate',
        component: () =>
          import(
            '@/js/modules/account/management/company/pages/WingmateCompanySettingsPage.vue'
          ),
        meta: {
          title: 'Wingmate Settings',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company/safety',
        name: 'company-safety',
        component: CompanySafety,
        meta: {
          title: 'Company Safety',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/account/company/standards/:country?',
        name: 'company-standards-list',
        component: CompanyStandardsListModule,
        meta: {
          title: 'Company Standards',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/compete',
        name: 'leaderboard',
        component: LeaderboardModule,
        meta: {
          title: 'Leaderboard',
          hideFooter: true,
          hideBreadcrumb: true,
        },
      },
    ],
  },
  {
    path: '/account',
    component: FigurePrintEditorLayout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: 'company/figure-print-layout-editor/:figurePrintLayoutId?',
        name: 'figure-print-layout-editor',
        component: FigurePrintLayoutEditorModule,
        meta: {
          title: 'Figure Print Layout Editor',
          featureRequired: FEATURES.FIGURE_PRINT_LAYOUTS,
        },
        props: (route) => {
          const { figurePrintLayoutId } = route.params;
          return {
            figurePrintLayoutId: figurePrintLayoutId
              ? parseInt(figurePrintLayoutId, 10)
              : null,
          };
        },
      },
    ],
  },
  {
    path: '/account/company/standards',
    component: StandardManagementLayout,
    meta: {
      auth: true,
      searchable: false,
    },
    children: [
      {
        path: 'manage/:standardId',
        searchable: false,
        name: 'company-standards-management',
        component: CompanyStandardsManagementModule,
        meta: {
          title: 'Standard Management',
        },
      },
    ],
  },
  {
    path: '/admin',
    component: AdminLayout,
    meta: {
      auth: true,
      adminRequired: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'admin_dashboard',
        component: AdminDashboardModule,
        meta: {
          title: 'Admin Dashboard',
        },
      },
      {
        path: 'usage/by-company',
        name: 'admin_company_usage',
        component: () =>
          import(
            '@/js/modules/admin/company-usage/pages/AdminCompanyUsagePage.vue'
          ),
        meta: {
          title: 'Company Usage Report',
        },
      },
      {
        path: 'usage/by-project',
        name: 'admin_project_usage',
        component: () =>
          import(
            '@/js/modules/admin/company-usage/pages/AdminProjectUsagePage.vue'
          ),
        meta: {
          title: 'Project Usage Report',
        },
      },
      {
        path: 'usage/by-user',
        name: 'admin_user_usage',
        component: () =>
          import(
            '@/js/modules/admin/company-usage/pages/AdminUserUsagePage.vue'
          ),
        meta: {
          title: 'User Usage Report',
        },
      },
      {
        path: 'project-browser',
        name: 'admin_project_browser',
        component: ProjectBrowserModule,
        meta: {
          title: 'Project Browser',
        },
      },
      {
        path: 'feature-highlight',
        name: 'admin_feature_highlight',
        component: FeatureHighlightModule,
        meta: {
          title: 'Feature Highlight',
        },
      },
      {
        path: 'standard-management',
        name: 'admin_standard_management',
        component: AdminStandardManagement,
        meta: {
          title: 'Standard Management',
        },
      },
      {
        path: 'audit-browser',
        name: 'admin_audit_browser',
        component: () => import('@/js/modules/admin/audit-browser/index.vue'),
        meta: {
          title: 'Audit Browser',
        },
      },
      {
        path: 'chemicals',
        name: 'admin_chemical_management',
        component: AdminChemicalModule,
        meta: {
          title: 'Admin Chemical Management',
        },
      },
      {
        path: 'vector-editor',
        name: 'admin_vector_editor',
        component: () => import('@/js/modules/admin/vector-editor/index.vue'),
        meta: {
          title: 'Vector Editor',
        },
      },
      {
        path: 'ai-review-guidelines',
        name: 'ai_review_guidelines_management',
        component: AIReviewGuidelineManagement,
        meta: {
          title: 'AI Review Guideline Management',
        },
      },
      {
        path: 'offline-management',
        name: 'admin_offline_management',
        component: () =>
          import('@/js/modules/admin/offline-management/index.vue'),
        meta: {
          title: 'Offline Management',
        },
      },
      {
        path: 'leaderboard-management',
        name: 'leaderboard_management',
        component: () =>
          import('@/js/modules/leaderboard/LeaderboardAdmin.vue'),
        meta: {
          title: 'Leagues & Leaderboard Management',
        },
      },
    ],
  },
  {
    path: '/support',
    component: TutorialLayout,
    meta: {
      auth: null,
    },
    children: [
      {
        path: '/support/:route?',
        name: 'help',
        component: SupportDashboardModule,
        meta: {
          title: 'Support',
          keywords: ['tutorials', 'guides', 'assistance'],
        },
      },
      {
        path: '/support/tags/:id',
        name: 'tutorials_by_tag',
        component: SupportTagModule,
        meta: {
          searchable: false,
          title: 'Support Tag',
        },
      },
      {
        path: '/support/tutorial/:id',
        name: 'tutorial',
        component: SupportTutorialModule,
        meta: {
          searchable: false,
          title: 'Support Tutorial',
        },
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    meta: {
      auth: false,
    },
    children: [
      {
        path: '/register',
        name: 'register',
        component: AccountRegisterModule,
        meta: {
          title: 'Sign Up',
        },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/js/pages/LoginPage.vue'),
        meta: {
          title: 'Login',
        },
      },

      {
        path: '/forgot/newpassword',
        name: 'reset',
        component: AccountResetModule,
        meta: {
          title: 'Forgot Password',
        },
      },
      {
        path: '/forgot/newpassword/:token',
        name: 'reset-token',
        component: AccountResetModule,
        meta: {
          title: 'Forgot Password Token',
          searchable: false,
        },
      },
    ],
  },
  {
    path: '/logout',
    component: AuthLayout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/',
        name: 'logout',
        component: () => import('@/js/pages/LogoutPage.vue'),
        meta: {
          title: 'Logout',
          auth: true,
        },
      },
    ],
  },
  {
    path: '/request',
    component: MiscLayout,
    meta: {
      auth: null,
    },
    children: [
      {
        path: '/project/request/:token',
        name: 'project_request',
        component: RequestModule,
        meta: {
          title: 'Laboratory Request',
          searchable: false,
        },
      },
      {
        path: '/p/:projectRef',
        name: 'p',
        component: RedirectToProject,
        meta: {
          title: 'Project Redirect',
        },
      },
      {
        path: '/open-project/:projectRef',
        component: RedirectToProject,
        meta: {
          title: 'Project Redirect',
        },
      },
      {
        path: '/404',
        component: NotFound,
        meta: {
          title: 'Page Not Found',
        },
      },
      {
        path: '/handover/:access_token/:page?',
        component: Handover,
        meta: {
          auth: null,
          searchable: false,
          title: 'Handover',
        },
      },
    ],
  },
  {
    path: '/map-export/:export_id',
    name: 'map_export',
    component: MapExportModule,
    meta: {
      title: 'Map Export',
      auth: true,
      figureOnlySupport: true,
    },
  },
  {
    path: '/map-iframe/:block_id/:token?',
    name: 'map_iframe',
    component: MapIFrameModule,
    meta: {
      title: 'Map iframe',
      auth: null,
      searchable: false,
    },
  },
  {
    path: '/onboarding',
    component: TutorialLayout,
    meta: {
      auth: true,
      hideFooter: true,
    },
    children: [
      {
        path: '/',
        name: 'onboarding',
        component: () => import('@/js/pages/Onboarding.vue'),
        meta: {
          title: 'Onboarding',
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export function useRouteInterceptors() {
  router.beforeEach(async (to, from, next) => {
    const projectStore = useProjectStore();
    if (to.matched.length === 0) {
      if (!to.path.startsWith('/p/')) {
        const currentProjectUuid = localStorage.getItem('project_uuid');
        if (currentProjectUuid) {
          const newMatch = router.resolve('/p/' + currentProjectUuid + to.path);
          if (newMatch) {
            return next(newMatch.location);
          }
        }
      }

      return next('/404');
    }

    if (to.name) {
      NProgress.start();
    }

    if (to.redirectedFrom != null && to.redirectedFrom == '/') {
      NProgress.done();
    }

    if (to.fullPath.startsWith('/p/')) {
      const uuid = to.fullPath.split('/')[2];
      if (localStorage.getItem('project_uuid') !== uuid) {
        if (uuid === 'null' || uuid === 'undefined') {
          console.error('Unexpected project uuid or ID, returning to project list');
          return next('/projects');
        }

        console.warn("Project changing due to url change");

        try {
          const project = await projectStore.loadProjectDetails(uuid);
          projectStore.updateProject(project);
        } catch (e: any) {
          if (e?.response?.status === 404) {
            useToastStore().error('Project not found');
            projectStore.resetProject();
            return next('/projects');
          }
          throw e;
        }
      }
    }

    window.document.title =
      to.meta && to.meta.title ? 'Datanest: ' + to.meta.title : 'Hub';

    const user = auth.getUser();
    const project = store.state.project as Project | null;

    const lastMatch = to.matched.at(-1);
    if (lastMatch) {
      if (user == null && to.matched.some((m) => m.meta.auth === true)) {
        console.warn('You must be logged in to access this page');
        try {
          useToastStore().error('You must be logged in to access this page.');
        } catch (e) {
          console.error(e);
        }
        return next('/login');
      }
      if (user && to.matched.some((m) => m.meta.auth === false)) {
        console.warn('User is logged in, redirecting from', to.fullPath);
        return next('/projects');
      }

      if (lastMatch.meta?.featureRequired) {
        const access = await awaitAccess(lastMatch.meta.featureRequired);
        if (!access) {
          console.warn('Feature required, redirecting');
          useToastStore().error('You do not have access to this feature.');
          return next('/projects');
        }
      }

      if (to.matched.some((m) => m.meta?.adminRequired)) {
        if (user != null && user.role == 'admin') {
          return next();
        }
        console.warn('Admin required, redirecting');
        return next('/projects');
      }

      if (to.matched.every((m) => !m.meta.projectRequired)) {
        return next();
      }
    }

    if (project) {
      if (project.lab_access_token) {
        console.warn('Project requires lab access token, redirecting');
        return next('/project/request/' + project.lab_access_token);
      }

      // if results required & no results then dont redirect
      if (
        to.matched.some((record) => record.meta.resultsRequired) &&
        !store.getters.project_has_matrix_upload
      ) {
        console.warn('Results required, no results. Rejecting route');
        return next(projectStore.getProjectBaseUrl());
      }

      if (!isSupportedRoute(project, to)) {
        console.warn('Unsupported route, redirecting to project base');
        return next(projectStore.getProjectBaseUrl());
      }

      return next();
    }

    if (!user) {
      return next();
    }

    const localProjectId = localStorage.getItem('project_uuid') || localStorage.getItem('project_id');
    if (!localProjectId) {
      projectStore.resetProject();
      return next('/projects');
    }
    console.log('Loading latest project details');

    try {
      const project = await projectStore
        .loadProjectDetails(localProjectId);

      console.log("Project details loaded", project);
      if (project.lab_access_token) {
        projectStore.resetProject();

        console.log('redirecting to lab access request');
        return next('/project/request/' + project.lab_access_token);
      }

      projectStore.updateProject(project);

      if (!isSupportedRoute(project, to)) {

        return next(projectStore.getProjectBaseUrl());
      }

      return next();
    } catch (error: any) {
      console.error(error);
      if (
        (error.response?.status === 400 || error.response?.status === 404) &&
        error.response?.data?.message
      ) {
        useToastStore().error(error.response.data.message);
      }
      projectStore.resetProject();

      return next('/projects');
    }
  });

  router.afterEach((to, from) => {
    NProgress.done();

    if (to.path === '/onboarding' || to.path === '/logout') {
      return;
    }

    const user = auth.getUser() as UserWithSession | null;
    const tenantJson = localStorage.getItem('tenant');
    const tenant = tenantJson ? (JSON.parse(tenantJson) as Tenant) : null;

    if (!user || auth.impersonating()) {
      return;
    }

    if (!user.onboarding_complete) {
      console.warn('redirecting to onboarding due to onboarding not complete');
      redirectToOnboarding(to);
    } else if (
      !user.mfa_verified &&
      !user.phone_number_confirmed_at &&
      user.sms_verification_available
    ) {
      console.warn(
        'redirecting to onboarding due to phone number not confirmed'
      );
      redirectToOnboarding(to);
    } else if (tenant?.requires_mfa && !user.mfa_active && !user.sso_active) {
      console.warn('redirecting to onboarding due to mfa required');
      redirectToOnboarding(to);
    } else if (!user.mfa_verified && user.mfa_active && !user.sso_active) {
      console.warn(
        'redirecting to onboarding due to mfa not verified',
        JSON.stringify(user)
      );
      redirectToOnboarding(to);
    } else if (!user.mfa_skipped && !user.mfa_active && !user.sso_active) {
      console.warn('redirecting to onboarding due to mfa not skipped');
      redirectToOnboarding(to);
    }
  });
}

function redirectToOnboarding(to: Route) {
  const url = new URL(window.location.href);
  if (['/map-export'].some((r) => url.pathname.includes(r))) {
    console.warn('Cannot redirect to onboarding from this route', to);
    captureMessage(
      'Cannot redirect to onboarding from this route: ' + to.fullPath,
      {
        extra: {
          to,
          location: window.location.href,
          user: auth.user(),
        },
      }
    );
    return;
  }
  console.trace('redirecting to onboarding', to);
  window.location.replace(
    '/onboarding?redirect=' + encodeURIComponent(to.path)
  );
}

function isSupportedRoute(project: Project, to) {
  if (
    (to.meta?.gatherRequired ||
      to.matched.some((meta) => meta?.gatherRequired)) &&
    !project.has_templates
  ) {
    console.warn('Gather required, no apps. Rejecting route');
    return false;
  }

  if (
    project.project_type === 1 &&
    !(
      to.meta?.figureOnlySupport !== false ||
      to.matched.some((meta) => meta?.figureOnlySupport !== false)
    )
  ) {
    console.warn(
      'This route is not available for this project type, rejecting',
      to
    );
    return false;
  }

  if (to.matched.length) {
    const lastMatch = to.matched[to.matched.length - 1];
    if (lastMatch.meta.roleRequired) {
      const { hasPermissionToAccess } = useViewRestriction(undefined, project);
      if (!hasPermissionToAccess(lastMatch.meta.roleRequired)) {
        useToastStore().error(
          'You do not have access to this module, ask the project manager for access.'
        );

        return false;
      }
    }
  }

  return true;
}

export default router;
